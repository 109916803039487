(function() {

  // console.log('script responsible for delayed gtm - initializing')


  /**
   * Checking for non live env.
   * @returns boolean
   */
  const checkForDevEnv = () => {
    const checkURL = ['staging.california', 'pantheonsite.io', 'lndo.site'];
    let found = false;
    for (var i = 0; i < checkURL.length; i++) {
      if(window.location.href.indexOf(checkURL[i]) > -1) {
          found = true;
      }
    }
    return found;
  }

  const getScriptUrl = () => {
    if (checkForDevEnv() == true) {
      //stage/dev tag
      return 'https://www.googletagmanager.com/gtm.js?id=GTM-W75WDPF';
    } else {
      //check for yext rfc page - class added in a custom callback
      if (document.querySelector('body').classList.contains('is-page-yext-rfc')) {
        //specific yext rfc live tag
        return 'https://www.googletagmanager.com/gtm.js?id=GTM-5WJVGJ9';
      } else {
        //normal live tag
        return 'https://www.googletagmanager.com/gtm.js?id=GTM-5FLTPB3';
      }
    }
  }

  /**
   * Load GTM script tag.
   */
  const initGTM = () => {
    if (window.gtmDidInit) {
      return false;
    }

    // Remove event listeners.
    document.removeEventListener('scroll', initGTMOnEvent);
    document.removeEventListener('mousemove', initGTMOnEvent);
    document.removeEventListener('touchstart', initGTMOnEvent);

    const script = document.createElement('script');

    // Ensure script tag is only added once.
    window.gtmDidInit = true;

    // Setup script tag.
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
        'gtm.uniqueEventId': 0,
      });
    };

    script.src = getScriptUrl();

    document.head.appendChild(script);
  };

  /**
   * Init GTM on event listener.
   * Ensures user actions are tracked if timeout is too long.
   *
   * @param {Object} event - The event being called.
   */
  const initGTMOnEvent = event => {
    initGTM();
    // Remove event listener.
    event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
  };


  // console.log('attaching gtm loader events')
  // Check user actions to initialize GTM early.
  document.addEventListener('scroll', initGTMOnEvent);
  document.addEventListener('mousemove', initGTMOnEvent);
  document.addEventListener('touchstart', initGTMOnEvent);

  // Fallback delayed initialization of Google Tag Manager.
  if (document.readyState === "complete"
      || document.readyState === "loaded"
      || document.readyState === "interactive") {
    // document has already been parsed/loaded
    setTimeout(initGTM, 3500);
  } else {
    //Load GTM when the document is ready.
    document.addEventListener('DOMContentLoaded', () => {
      setTimeout(initGTM, 3500);
    });
  }

})();
